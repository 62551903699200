.contactus {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contactus-container {
  padding: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

}

.contactus-head {
  font-size: 6rem;
  font-family: turnkey-bold;
  color: #36be00;
  letter-spacing: -0.1rem;

}
.cu-circle-light {
  position: absolute;
  align-items: center;
  z-index: -1;
  filter: blur(200px);
  background-color: #36be00;
  border-radius: 100%;
  width: 15.1875rem;
  height: 15.1875rem;
  position: absolute;
}

.cu-caption {
  text-align: center;
  color: white;
  letter-spacing: 0.05rem;
  font-family: turnkey-light;
  font-size: 1rem;
  width: 50%;
}

.c-circle-light {
  position: absolute;
  align-items: center;
  z-index:10;
  filter: blur(200px);
  background-color: #36be00;
  border-radius: 100%;
  width: 15.1875rem;
  height: 15.1875rem;
  position: absolute;
}
