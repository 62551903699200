.EventCard {
  margin: auto;
  width: 100%;
  height: 30vh;
  border-radius: 1rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  font-size: 16px;
}
.EventCard .thumb {
  width: auto;
  height: 30vh;
  background-size: cover;
  background-position: center;
  border-radius: 3px;
  overflow: hidden;
}
.EventCard .infos {
  width: auto;
  height: 30vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  transition: 0.6s 0.15s cubic-bezier(0.17, 0.67, 0.5, 1.03);
  border-radius: 1rem;
}

.EventCard .infos .title {
  position: relative;
  margin: 7px 0;
  letter-spacing: 2px;
  color: #152536;
  font-size: 1em;
  font-weight: 700;
  text-transform: capitalize;
  text-shadow: 0 0 0px #32577f;
}

.EventCard .infos .detail {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 0.85em;
  color: rgba(21, 37, 54, 0.7);
}

.EventCard .infos .txt {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 10%;
  line-height: 2;
  font-size: 0.95em;
  color: rgba(21, 37, 54, 0.7);
  opacity: 0;
  transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
}
.EventCard .infos .details {
  padding-bottom: 10%;
  letter-spacing: 1px;
  color: #4e958b;
  font-size: 0.9em;
  text-transform: uppercase;
  cursor: pointer;
  opacity: 0;
  transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
}

.EventCard .ellipss {
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.5em;
  max-height: 3em;
}

.EventCard:hover .ellipss {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  line-height: 1.5em;
  max-height: 7.5em;
} 
.event:hover .infos {
  -webkit-transform: translateY(-30vh);
  transform: translateY(-30vh);
}

.EventCard:hover .infos {
  overflow-y: auto;
}

.EventCard .infos::-webkit-scrollbar {
  width: 5px;
}

.EventCard .infos::-webkit-scrollbar-track {
  border-radius: 5px;
}

.EventCard .infos::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.EventCard:hover .infos .seats,
.EventCard:hover .infos .txt,
.EventCard:hover .infos .details {
  opacity: 1;
}
.EventCard {
  text-align: left;
}

.past,
.upcoming {
  margin-bottom: 20px;
}

.events{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.events1{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 50px 0px; /* 50px row gap, 20px column gap */
  justify-content: space-around; 
  padding-top: 0px;
}

.event {
  width: 300px;
  height: 450px;
  border-radius: 12px;
  background: rgba(10, 10, 10, 0.8);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
  transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  border: 1px solid rgba(40, 40, 40, 0.6);
}

.event:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.5), 0 0 15px rgba(54, 190, 0, 0.2);
}

/* Glowing border effect */
.event::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  box-shadow: 0 0 0 0 rgba(54, 190, 0, 0);
  transition: box-shadow 0.5s ease;
  z-index: -1;
  pointer-events: none;
}

.event:hover::before {
  box-shadow: 0 0 20px 3px rgba(54, 190, 0, 0.3);
}

/* Main image styling */
.EventCard {
  width: 100%;
  height: 160px;
  overflow: hidden;
  position: relative;
  border-radius: 12px 12px 0 0;
}

.thumb {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: transform 0.5s ease;
}

.event:hover .thumb {
  transform: scale(1.08);
}

/* Image overlay gradient */
.thumb::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.event:hover .thumb::after {
  opacity: 1;
}

/* Info box */
.infos {
  position: relative;
  width: 100%;
  height: auto;
  background: transparent;
  border-radius: 0 0 12px 12px;
}

/* Main content area */
.information {
  padding: 16px;
}

.event-card-inner-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Title styling */
.ellipss {
  font-size: 1.5rem;
  color: #36be00;
  font-family: turnkey-medium;
  margin: 0;
  background: linear-gradient(90deg, #36be00, #56d429);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  transform: translateY(0);
  transition: transform 0.3s ease;
}

.event:hover .ellipss {
  transform: translateY(-3px);
}

/* Details styling */
.detail {
  color: #fff;
  font-size: 0.9rem;
  font-family: turnkey-medium;
  display: flex;
  align-items: center;
  margin: 4px 0;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.event:hover .detail {
  opacity: 1;
}

.detail::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-right: 8px;
  background-color: #36be00;
  border-radius: 50%;
}

/* Description styling */
.txt {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.8rem;
  font-family: turnkey-light;
  line-height: 1.5;
  margin-top: 8px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  transition: -webkit-line-clamp 0.4s ease, color 0.3s ease;
}



/* Button styling */
.details {
  display: inline-block;
  margin-top: 16px;
  padding: 8px 16px;
  background-color: rgba(54, 190, 0, 0.1);
  color: #36be00;
  font-size: 0.75rem;
  text-decoration: none;
  border-radius: 20px;
  transition: all 0.3s ease;
  border: 1px solid rgba(54, 190, 0, 0.3);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  position: relative;
  overflow: hidden;
}

.details::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #36be00;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  z-index: -1;
}

.details:hover {
  color: #fff;
}

.details:hover::before {
  transform: translateX(0);
}

/* Card hover animations */
.event {
  animation: fadeIn 0.5s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile responsiveness */
@media screen and (max-width: 450px) {
  .event {
    width: 280px;
    height: 430px;
  }
  
  .EventCard {
    height: 140px;
  }
  
  .ellipss {
    font-size: 1.3rem;
  }
  
  .events1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
}

/* Enhanced Dialog styling */
.event-dialog {
  margin: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  padding: 0;
  border: none;
  border-radius: 16px;
  width: 85%;
  max-width: 650px;
  height: 600px;
  background: #171717;
  color: white;
  box-shadow: 0 15px 40px -10px rgba(0, 0, 0, 0.8), 0 0 20px rgba(54, 190, 0, 0.1);
  overflow: hidden;
  border: 1px solid rgba(54, 190, 0, 0.15);
  z-index: 9999;
}

.event-dialog::backdrop {
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(8px);
}

.modal-open {
  overflow: hidden;
}

.event-dialog-content {
  position: relative;
  height: 100%;
  max-height: 85vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.event-dialog-close {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.85);
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  transition: all 0.2s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

.event-dialog-close:hover {
  background: #36be00;
  color: white;
  transform: scale(1.1);
}

.event-dialog-header {
  position: relative;
  height: 190px; /* Reduced height */
  overflow: hidden;
}

.event-dialog-banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  filter: brightness(0.7);
}

.event-dialog-banner::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60%;
  background: linear-gradient(to top, #111111, rgba(17, 17, 17, 0));
}

.event-dialog-logo {
  position: absolute;
  bottom: 12px;
  left: 20px;
  width: 60px; /* Smaller logo */
  height: 60px;
  object-fit: contain;
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.5));
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 5px;
  border: 1px solid rgba(54, 190, 0, 0.2);
}

.event-dialog-title {
  position: absolute;
  bottom: 20px;
  left: 95px; /* Adjusted positioning */
  font-family: 'turnkey-bold', sans-serif;
  font-size: 2rem; /* Slightly smaller */
  margin: 0;
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  max-width: 75%; /* Prevent title from getting too wide */
  line-height: 1.2;
}

.event-dialog-body {
  padding: 22px;
  background: linear-gradient(to bottom, #171717, #1a1a1a);
  flex: 1;
  overflow-y: auto;
}

.event-dialog-details {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 22px;
  padding-bottom: 18px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
}

.event-detail {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 5px 12px;
  background: rgba(54, 190, 0, 0.07);
  border: 1px solid rgba(54, 190, 0, 0.15);
  border-radius: 30px;
  font-family: 'turnkey-medium', sans-serif;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.event-detail svg {
  opacity: 0.9;
}

.event-dialog-description h3 {
  font-family: 'turnkey-medium', sans-serif;
  color: #36be00;
  margin-bottom: 12px;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.event-dialog-description p {
  font-family: 'turnkey-light', sans-serif;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 25px;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  text-align: left;
}

.event-dialog-description {
  width: 100%;
  max-width: 100%;
  overflow-wrap: break-word;
  word-break: normal;
}

.event-dialog-poster {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.event-dialog-poster img {
  max-width: 100%;
  max-height: 320px;
  border-radius: 6px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
}

/* Add register button styles */
.event-dialog-register {
  margin: 25px 0;
  display: flex;
  justify-content: center;
}

.register-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #36be00;
  color: white;
  padding: 12px 25px;
  border-radius: 30px;
  font-family: 'turnkey-medium', sans-serif;
  font-size: 1rem;
  text-decoration: none;
  transition: all 0.3s ease;
  border: none;
  box-shadow: 0 4px 15px rgba(54, 190, 0, 0.3);
  letter-spacing: 0.5px;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
}

.register-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  transform: translateX(-100%) rotate(45deg);
  transition: transform 0.6s ease;
}

.register-button:hover {
  background: #2fa000;
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(54, 190, 0, 0.4);
}

.register-button:hover::before {
  transform: translateX(100%) rotate(45deg);
}

.register-button:active {
  transform: translateY(1px);
}

/* Prize detail styling */
.event-detail-prize {
  background: rgba(255, 215, 0, 0.08);
  border-color: rgba(255, 215, 0, 0.2);
}

.event-detail-prize svg path {
  stroke: gold;
}

/* Fix scrollbar for all browsers */
.event-dialog-content {
  scrollbar-width: thin;
  scrollbar-color: rgba(54, 190, 0, 0.3) rgba(0, 0, 0, 0.2);
}

.event-dialog-content::-webkit-scrollbar {
  width: 6px;
}

.event-dialog-content::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.event-dialog-content::-webkit-scrollbar-thumb {
  background: rgba(54, 190, 0, 0.3);
  border-radius: 3px;
}

.event-dialog-content::-webkit-scrollbar-thumb:hover {
  background: rgba(54, 190, 0, 0.5);
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .event-dialog {
    width: 92%;
    max-height: 88vh;
  }
  
  .event-dialog-header {
    height: 160px;
  }
  
  .event-dialog-title {
    font-size: 1.5rem;
    left: 20px;
    bottom: 55px;
    max-width: 85%;
  }
  
  .event-dialog-logo {
    bottom: 12px;
    left: 20px;
    width: 45px;
    height: 45px;
  }
  
  .event-dialog-body {
    padding: 18px;
    padding-top: 25px;
  }
  
  .event-detail {
    padding: 4px 10px;
    font-size: 0.8rem;
  }
  
  .event-dialog-description h3 {
    font-size: 1rem;
  }
  
  .event-dialog-description p {
    font-size: 0.9rem;
    line-height: 1.6;
  }
}

/* Animation for dialog opening */
@keyframes dialogFadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.event-dialog[open] {
  animation: dialogFadeIn 0.3s ease-out;
}

