.rs-head {
  font-family: turnkey-bold;
  color: white;
  font-size: 5rem;
  letter-spacing: -2px;
  margin-bottom: 0px;
}

.rs-caption {
  font-family: turnkey-light;
  font-size: 1rem;
  font-weight: 100;
  color: white;
  width: 45%;
  line-height: 154.513%; /* 25.29px */
  letter-spacing: 0.5px;
  text-align: center;
}

.bottom-image {
  position: absolute;
  top: 5%;
  height: 130vh;
  width: 90%;
  background-image: url("../../../public/images/circles.png");
  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -10;
  overflow: hidden3;
  margin-top: 3%;

}

.hw1-container {
  margin-top: 5%;
  width: 10%;
  height: 4vh;
  border-radius: 27.44px;
  border: 0.61px solid #36be00;
  box-shadow: 0px 0px 4.878px 2.439px rgba(54, 190, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 10px;
}

.hw1-text {
  font-family: turnkey-light;
  font-size: 1rem;
  color: white;
  margin: 0px;
  width: fit-content;
  text-overflow: ellipsis;
}

.hw1-arrow {
  width: 10%;
  height: 100%;
  position: relative;
  background-image: url("../../../public/images/arrow.png");
  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.rs-top-layer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  padding-left: 5%;
  padding-right: 5%;
  height: 100vh;
  gap: 10px;
  width: 100vw;
  margin-top: 50px;
}

/* Resources Content Container */
.resources-content {
  width: 90%;
  margin: 0 auto;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
}

/* Technology title */
.tech-title {
  font-family: turnkey-medium;
  font-size: 3rem;
  color: white;
  margin-bottom: 40px;
  border-bottom: 3px solid #33D90B;
  padding-bottom: 10px;
  width: fit-content;
}

/* Resource category */
.resource-category {
  margin-bottom: 60px;
}

.category-title {
  font-family: turnkey-medium;
  font-size: 1.8rem;
  color: white;
  margin-bottom: 20px;
  position: relative;
  padding-left: 15px;
}

.category-title::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 25px;
  background-color: #33D90B;
  border-radius: 3px;
}

/* Scrollable container for resource cards */
.resources-scroll-container {
  width: 100%;
  overflow-x: auto;
  padding: 10px 0;
}

.resources-scroll-container::-webkit-scrollbar {
  height: 8px;
}

.resources-scroll-container::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.resources-scroll-container::-webkit-scrollbar-thumb {
  background: rgba(54, 190, 0, 0.5);
  border-radius: 10px;
}

.resources-scroll-container::-webkit-scrollbar-thumb:hover {
  background: rgba(54, 190, 0, 0.8);
}

.resources-scroll {
  display: flex;
  gap: 25px;
  padding: 10px 5px;
  width: max-content;
}

/* Technology selector */
.tech-select-container {
  position: relative;
  width: 280px;
  margin: 0 0 50px 0;
  z-index: 50;
}

.custom-dropdown-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 20px;
  background: rgba(15, 15, 15, 0.95);
  color: white;
  border: 1px solid #36be00;
  border-radius: 8px;
  font-family: 'turnkey-medium', sans-serif;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease;
}

.custom-dropdown-button:hover {
  background: rgba(25, 25, 25, 0.95);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4), 0 0 8px rgba(54, 190, 0, 0.2);
  transform: translateY(-2px);
}

/* Dropdown dot */
.dropdown-dot {
  width: 8px;
  height: 8px;
  background: #36be00;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  box-shadow: 0 0 8px #36be00;
}

/* Dropdown arrow */
.dropdown-arrow {
  border: solid #36be00;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 10px;
  transform: rotate(45deg);
  transition: transform 0.2s ease;
}

/* Dropdown menu */
.custom-dropdown-menu {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 100%;
  background: rgba(15, 15, 15, 0.98);
  border: 1px solid #36be00;
  border-radius: 8px;
  overflow-y: auto;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  max-height: 350px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: rgba(54, 190, 0, 0.5) rgba(255, 255, 255, 0.05);
}

.custom-dropdown-menu::-webkit-scrollbar {
  width: 10px;
  display: block;
}

.custom-dropdown-menu::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
}

.custom-dropdown-menu::-webkit-scrollbar-thumb {
  background: rgba(54, 190, 0, 0.5);
  border-radius: 10px;
  border: 2px solid rgba(15, 15, 15, 0.5);
}

.custom-dropdown-menu::-webkit-scrollbar-thumb:hover {
  background: rgba(54, 190, 0, 0.8);
}

/* Dropdown items */
.custom-dropdown-item {
  padding: 12px 20px;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  font-family: 'turnkey-light', sans-serif;
  position: relative;
}

.custom-dropdown-item:last-child {
  border-bottom: none;
  margin-bottom: 5px;
}

.custom-dropdown-item:hover {
  background: rgba(54, 190, 0, 0.15);
  padding-left: 25px;
}

.custom-dropdown-item.active {
  color: #36be00;
  background: rgba(54, 190, 0, 0.08);
  border-left: 3px solid #36be00;
}

/* Add a gradient indicator at the bottom of the dropdown menu */
.custom-dropdown-menu::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background: linear-gradient(to top, rgba(15, 15, 15, 0.95), transparent);
  pointer-events: none;
  opacity: 0.8;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  z-index: 5;
}

.resources-contents-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  top: 0%;
}

@media screen and (max-width: 600px) {
  .hw1-container{
    width: 50%;
  }

  .rs-head{
    font-size: 40px;
  }

  .rs-caption{
    width: 100%;
    font-size: 12px;
  }

  .bottom-image{
    top: -12%;
    width: 100vw;
  }

  .c-circle-light {
    position: absolute;
    align-items: center;
    z-index: 1;
    filter: blur(200px);
    background-color: #36be00;
    border-radius: 100%;
    width: 15.1875rem;
    height: 15.1875rem;
    position: absolute;
  }

  .resources-contents-container{
    top: -10%;
  }
  
  .tech-title {
    font-size: 2rem;
  }
  
  .category-title {
    font-size: 1.5rem;
  }
  
  .resources-content {
    width: 95%;
  }
  
  /* Mobile dropdown improvements */
  .custom-dropdown-menu {
    max-height: 250px; /* Shorter on mobile for better UX */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  }
  
  .custom-dropdown-item {
    padding: 15px 20px; /* Larger touch targets */
  }
}

/* Ultra Premium Custom Dropdown */
.tech-select-container {
  position: relative;
  width: 280px;
  margin: 40px auto;
  perspective: 800px;
  z-index: 50;
}

.tech-select {
  position: relative;
  width: 100%;
  height: 60px;
  background: rgba(15, 15, 15, 0.8);
  border: 1px solid rgba(54, 190, 0, 0.3);
  border-radius: 8px;
  padding: 0;
  font-family: 'turnkey-medium', sans-serif;
  color: #fff;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: 
    0 5px 20px rgba(0, 0, 0, 0.3),
    0 0 0 1px rgba(54, 190, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.05);
  z-index: 10;
}

.tech-select:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, 
    transparent 0%, 
    rgba(54, 190, 0, 0.03) 50%,
    transparent 100%);
  background-size: 200% 100%;
  animation: shimmer 3s infinite;
  pointer-events: none;
}

@keyframes shimmer {
  0% { background-position: 100% 0; }
  100% { background-position: -100% 0; }
}

.tech-select-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 100%;
  position: relative;
  z-index: 2;
}

.tech-select-value {
  font-size: 17px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-shadow: 0 0 10px rgba(54, 190, 0, 0.5);
  position: relative;
  display: flex;
  align-items: center;
}

.tech-select-value:before {
  content: "";
  width: 8px;
  height: 8px;
  background: #36be00;
  border-radius: 50%;
  margin-right: 12px;
  box-shadow: 0 0 10px #36be00;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { opacity: 0.5; transform: scale(0.8); }
  50% { opacity: 1; transform: scale(1.2); }
  100% { opacity: 0.5; transform: scale(0.8); }
}

.tech-select-arrow {
  position: relative;
  width: 20px;
  height: 20px;
}

.tech-select-arrow:before, 
.tech-select-arrow:after {
  content: "";
  position: absolute;
  background: #36be00;
  width: 12px;
  height: 2px;
  top: 50%;
  transition: all 0.3s ease;
}

.tech-select-arrow:before {
  transform: rotate(45deg);
  left: 2px;
}

.tech-select-arrow:after {
  transform: rotate(-45deg);
  right: 2px;
}

.tech-select.open .tech-select-arrow:before {
  transform: rotate(-45deg);
}

.tech-select.open .tech-select-arrow:after {
  transform: rotate(45deg);
}

.tech-select-dropdown {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 100%;
  background: rgba(18, 18, 18, 0.98);
  border: 1px solid rgba(54, 190, 0, 0.2);
  border-radius: 8px;
  max-height: 0;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  opacity: 0;
  transform-origin: top center;
  transform: rotateX(-15deg) translateY(-10px);
  box-shadow: 
    0 15px 30px rgba(0, 0, 0, 0.4),
    0 5px 15px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 100;
  pointer-events: none;
}

.tech-select.open .tech-select-dropdown {
  max-height: 300px;
  opacity: 1;
  transform: rotateX(0) translateY(0);
  pointer-events: all;
  overflow-y: auto;
}

.tech-select-option {
  padding: 15px 20px;
  font-family: 'turnkey-light', sans-serif;
  font-size: 16px;
  color: white;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.tech-select-option:last-child {
  border-bottom: none;
}

.tech-select-option:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 3px;
  background: #36be00;
  transform: scaleY(0);
  transition: transform 0.2s ease;
}

.tech-select-option:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  background: rgba(54, 190, 0, 0.1);
  transition: width 0.3s ease;
  z-index: -1;
}

.tech-select-option:hover {
  color: #fff;
}

.tech-select-option:hover:before {
  transform: scaleY(1);
}

.tech-select-option:hover:after {
  width: 100%;
}

.tech-select-option.selected {
  color: #36be00;
  background: rgba(54, 190, 0, 0.08);
  padding-left: 25px;
}

.tech-select-option.selected:before {
  transform: scaleY(1);
}

/* Hover glow effect */
.tech-select:hover {
  border-color: rgba(54, 190, 0, 0.6);
  box-shadow: 
    0 8px 25px rgba(0, 0, 0, 0.4),
    0 0 15px rgba(54, 190, 0, 0.2),
    inset 0 0 0 1px rgba(255, 255, 255, 0.07);
  transform: translateY(-2px);
}

.section-title {
  color: #fff;
  width: fit-content;
  font-family: turnkey-medium;
  font-size: 40px;
  padding-bottom: 0.5%;
  margin: 50px 0px 50px 0px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-bottom: 3px solid #33d90b;
}

.rs-body{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resource-section{
  width: 90%;
  margin-bottom: 100px;
}

.courses{
  width: 100%;
  overflow-x: scroll;
}

.tech-list{
  width: max-content;
  display: flex;
  gap: 30px;
}

/* Dropdown container */
.custom-dropdown-container {
  position: relative;
  width: 280px;
  margin: 40px auto;
  z-index: 999;
}

/* Dropdown button */
.custom-dropdown-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 20px;
  background: rgba(15, 15, 15, 0.95);
  color: white;
  border: 1px solid #36be00;
  border-radius: 8px;
  font-family: 'turnkey-medium', sans-serif;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease;
}

.custom-dropdown-button:hover {
  background: rgba(25, 25, 25, 0.95);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4), 0 0 8px rgba(54, 190, 0, 0.2);
  transform: translateY(-2px);
}

/* Dropdown dot */
.dropdown-dot {
  width: 8px;
  height: 8px;
  background: #36be00;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  box-shadow: 0 0 8px #36be00;
}

/* Dropdown arrow */
.dropdown-arrow {
  border: solid #36be00;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 10px;
  transform: rotate(45deg);
  transition: transform 0.2s ease;
}

/* Dropdown menu */
.custom-dropdown-menu {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 100%;
  background: rgba(15, 15, 15, 0.98);
  border: 1px solid #36be00;
  border-radius: 8px;
  overflow-y: auto;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  max-height: 350px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: rgba(54, 190, 0, 0.5) rgba(255, 255, 255, 0.05);
}

.custom-dropdown-menu::-webkit-scrollbar {
  width: 10px;
  display: block;
}

.custom-dropdown-menu::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
}

.custom-dropdown-menu::-webkit-scrollbar-thumb {
  background: rgba(54, 190, 0, 0.5);
  border-radius: 10px;
  border: 2px solid rgba(15, 15, 15, 0.5);
}

.custom-dropdown-menu::-webkit-scrollbar-thumb:hover {
  background: rgba(54, 190, 0, 0.8);
}

/* Dropdown items */
.custom-dropdown-item {
  padding: 12px 20px;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  font-family: 'turnkey-light', sans-serif;
}

.custom-dropdown-item:last-child {
  border-bottom: none;
}

.custom-dropdown-item:hover {
  background: rgba(54, 190, 0, 0.15);
  padding-left: 25px;
}

.custom-dropdown-item.active {
  color: #36be00;
  background: rgba(54, 190, 0, 0.08);
  border-left: 3px solid #36be00;
}

