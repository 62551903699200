.containner {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-transform: uppercase;
}
.containner .card {
  position: relative;
  width: 20vw;
  height: 60vh;
  background-color: black;
  backdrop-filter: blur(4px);
  border: 1px solid #34d90b64;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5%;
  transition: box-shadow 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.containner .card .content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.5s;
}
.containner .card:hover .content {
  opacity: 1;
  transform: translateY(-20px);
}
.containner .card .content .imgBx {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  border: 0.5rem solid  #ffffff1d;
}

.containner .card .content .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.containner .card .content .contentBx h3 {
  color: #fff;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  line-height: 1.1em;
  margin: 15% 0px;
}

.containner .card .content .contentBx h3 span {
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
}

.containner .card .sci {
  position: absolute;
  display: flex;
  bottom: 10%;
}

.containner .card .sci li {
  list-style: none;
  margin: 0 10px;
  transform: translateY(40px);
  transition: 0.5s;
  opacity: 0;
}
.containner .card .sci li a {
  color: #fff;
}

.containner .card:hover .sci li {
  transform: translateY(0);
  opacity: 1;
}
.containner .card .sci li:nth-child(2) {
  transition-delay: 0.1s;
}
.containner .card .sci li:nth-child(3) {
  transition-delay: 0.2s;
}


/*Changes */
.sci{
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: space-around;
  padding: 0px;
  margin: 0px;
}

.git{
  height: 20px;
  width: 20px;
  background-image: url("../../../public/images/git.png");
  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left bottom;
  padding: 0px;
  margin: 0px;
}

.linkedin{
  height: 20px;
  width: 20px;
  background-image: url("../../../public/images/linkedin.png");
  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left bottom;
  padding: 0px;
  margin: 0px;
}

.insta{
  height: 20px;
  width: 20px;
  background-image: url("../../../public/images/insta.png");
  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left bottom;
  padding: 0px;
  margin: 0px;
}

.member-name{
  font-family: turnkey-medium;
}


.member-position{
  font-family: turnkey-light;
}


@media (max-width: 450px) {
  
}

@media (max-width: 600px) {
  .containner .card {
    margin-top: 30px;
    width: 250px;
    height: 400px;
  }
}
