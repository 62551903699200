.ResourceCard {
  width: 270px;
  height: 350px;
  border-radius: 20px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  font-size: 16px;
  border: 1px solid #1B1B1B;
  overflow-y: hidden;
}

.ResourceCard:hover {
  box-shadow: 0px 0px 20px 5px rgba(54, 190, 0, 0.3);
  transition: 0.6s 0.15s cubic-bezier(0.17, 0.67, 0.5, 1.03);
}

.ResourceCard .thumb .rs-image-logo {
  height: 80px;
  width: 80px;
  object-fit: contain;
  margin-bottom: 30px;
  filter: brightness(1.2);
}

.ResourceCard .thumb .rs-inner-title {
  color: white;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 15px;
  padding: 0 15px;
}

.ResourceCard .thumb {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  background-size: cover;
  background-position: center;
  border-radius: 3px;
  background-color: #080808;
  position: relative;
}

.rs-type-tag {
  background-color: #33D90B;
  color: black;
  font-size: 0.75rem;
  padding: 4px 12px;
  border-radius: 15px;
  font-family: turnkey-light;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.5px;
  display: inline-block;
  min-width: 40px;
  text-align: center;
}

.ResourceCard .infos {
  width: auto;
  height: 350px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #33D90B;
  transition: 0.6s 0.15s cubic-bezier(0.17, 0.67, 0.5, 1.03);
  padding: 30px 30px;
}

.ResourceCard .title {
  padding-right: 40px;
}

.ResourceCard .infos .title {
  position: relative;
  margin: 7px 0;
  color: black;
  font-size: 3rem;
  text-transform: capitalize;
  text-shadow: 0 0 0px #32577f;
  font-family: turnkey-medium;
}

.ResourceCard .infos .time {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-transform: none;
  height: 23px;
  font-size: 0.9em;
  color: rgba(67, 83, 100, 0.7);
  background-size: 100% auto;
  display: inline-block;
}

.ResourceCard .infos .detail {
  margin-bottom: 10px;
  font-size: 0.85em;
  color: rgba(21, 37, 54, 0.7);
}

.ResourceCard .infos .txt {
  border-top: 2px solid rgba(0, 0, 0, 0.679);
  padding-top: 13px;
  line-height: 2;
  font-size: 0.95em;
  color: black;
  opacity: 0;
  transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
}

.ResourceCard .infos .details {
  padding-bottom: 19px;
  letter-spacing: 1px;
  color: #4e958b;
  font-size: 0.9em;
  text-transform: uppercase;
  cursor: pointer;
  opacity: 0;
  transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
}

.ResourceCard .ellipss {
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.5em;
  max-height: 3em;
}

.ResourceCard:hover .ellipss {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  line-height: 1.5em;
  max-height: 7.5em;
}

.ResourceCard:hover .infos {
  -webkit-transform: translateY(-400px);
  transform: translateY(-400px);
}

.ResourceCard:hover .infos {
  overflow-y: auto;
}

.ResourceCard .infos::-webkit-scrollbar {
  width: 5px;
}

.ResourceCard .infos::-webkit-scrollbar-track {
  border-radius: 5px;
}

.ResourceCard .infos::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.ResourceCard:hover .infos .seats,
.ResourceCard:hover .infos .txt,
.ResourceCard:hover .infos .details {
  opacity: 1;
}

.ResourceCard {
  text-align: left;
}

.infos-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.infos-link {
  font-family: turnkey-medium;
  font-size: 18px;
  color: black;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease;
  width: fit-content;
  border-bottom: 2px solid black;
  padding-bottom: 2px;
}

.infos-link:hover {
  color: #080808;
  transform: translateX(5px);
}

.visit-text {
  margin-right: 8px;
}

.visit-arrow {
  font-size: 20px;
  transition: transform 0.3s ease;
}

.infos-link:hover .visit-arrow {
  transform: translateX(3px);
}

.type-chip {
  display: inline-block;
  background-color: black;
  color: white;
  padding: 4px 12px;
  border-radius: 15px;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-family: turnkey-light;
  margin-top: 10px;
  min-width: 40px;
  text-align: center;
}

.infos-title {
  color: black;
  font-family: turnkey-medium;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

@media screen and (max-width: 600px) {
  .ResourceCard {
    width: 250px;
  }
}

.technology-resources {
  padding: 15px;
}