body {
  background-color: black;
}

.NAVBAR{
    position: fixed;

    top: 0px;
    width: 100vw;
    z-index: 1000;
}



.NAVBAR.hidden {
  transform: translateY(-100%);
}

.NAVBAR.visible {
  transform: translateY(0);
}

nav {
  margin-top: 1%;
  margin-left: 3%;
  margin-right: 3%;
}

.nav-item {
  display: flex;
  align-items: center;
}

.navbar-toggler-icon{
  background-image: url("../../public/images/nav.png");
  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  width: 1.5em;
  height: 1.5em;
  position: relative;
  z-index: 5;
}

.navbar-toggler {
  border: none;
  outline: none;
  background: transparent;
  padding: 0.25rem 0.75rem;
  position: relative;
  z-index: 1001;
}

.navbar-toggler:focus {
  box-shadow: none;
  outline: none;
}

.navbar-toggler:hover .navbar-toggler-icon {
  opacity: 0.8;
}

ul.navbar-nav.mx-auto.mb-2.mb-lg-0.nav-bar {
  border: 1px solid white;
  border-radius: 100px;
  width: 45%;
  height: 55.089px;
  color: white;
  border-radius: 27.545px;
  border: 0.874px solid #363636;
  backdrop-filter: blur(5px);
  box-shadow: 0px 0px 3.498px 8.744px rgba(0, 0, 0, 0.25);
}

.nav-link,
.nav-link.active {
  font-size: 17px;
  font-family: turnkey-light;
  color: white !important;
}

.nav-link:hover{
  color: #36be00 !important;
}


.joinus-btn {
  width: 146px;
  height: 47.351px;
  border-radius: 32.751px;
  border: 1px solid rgba(255, 255, 255, 0.9);
  background: #36be00;
  font-family: turnkey-medium;
  color: white;
  font-size: 20px;
}

.scope-logo {
  width: 10%;
  height: 10vh;
  position: relative;
  background-image: url("../../public/images/scope-logo.png");
  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1000;
  transition: width 0.3s ease;
}


/* Desktop: 2x4 grid */
@media (min-width: 1024px) {
  
}

/* Tablet: 3x3 grid */
@media (min-width: 768px) and (max-width: 1023px) {
  
}


/* Mobile: 3x3 grid */
@media (max-width: 768px) {
  .scope-logo{
    width: 30%;
    flex-shrink: 0; /* Prevent the logo from shrinking */
  }
  .nav-link.active {
    background-color: transparent;
    color: #36be00 !important;
    padding: 10px 30px 10px 30px;
    width: 200%;
  }
  ul.navbar-nav.mx-auto.mb-2.mb-lg-0.nav-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 0px;
    border: 0px;
    background-color: black;
    transition: all 0.3s ease-in-out;
  }
  .joinus-btn {
    margin-top: 15px;
    margin-bottom: 10px;
    width: 50%;
    height: 50px;
    background: #36be00;
    color: white;
    border-radius: 32.751px;
    font-family: turnkey-medium;
    font-size: 20px;
    border: 1px solid rgba(255, 255, 255, 0.9);
  }
  form.d-flex {
    justify-content: center;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 15px;
    background-color: black;
  }
  .nav-item {
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  }
  .navbar-collapse {
    background-color: black;
    padding-bottom: 20px;
    border-radius: 27.545px;
  }
  a.linktree {
    display: block;
    width: 100%;
    text-align: center;
  }
  .navbar-toggler {
    margin-left: auto; /* Push to right */
    position: relative;
    z-index: 1001;
    min-width: 40px;
    min-height: 40px;
  }
  .container-fluid.d-flex {
    justify-content: space-between;
    align-items: center;
  }
}

/* Add smooth transitions to navbar collapse */
.navbar-collapse {
  transition: all 0.45s cubic-bezier(0.25, 0.1, 0.25, 1);
  will-change: height, opacity;
}

.navbar-collapse.collapsing {
  height: auto !important;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 0;
}

.navbar-collapse.show {
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}

/* Smooth transitions for the navbar items */
.nav-link {
  transition: color 0.2s ease-in-out;
}

/* Make join button transitions smooth too */
.joinus-btn {
  transition: background-color 0.2s ease-in-out, 
              color 0.2s ease-in-out, 
              border-color 0.2s ease-in-out;
}

/* Improve the basic navbar animations */
.NAVBAR {
  transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.NAVBAR.hidden {
  transform: translateY(-100%);
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.NAVBAR.visible {
  transform: translateY(0);
  transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* Mobile menu items fade-in effect */
@media (max-width: 768px) {
  .navbar-collapse {
    max-height: 0;
    overflow: hidden;
  }
  
  .navbar-collapse.show {
    max-height: 100vh;
  }
  
  /* Staggered animation for nav items */
  .nav-item {
    opacity: 0;
    transform: translateY(20px);
    transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1), 
                opacity 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  }
  
  .navbar-collapse.show .nav-item {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Delay each nav item to create a staggered effect */
  .navbar-collapse.show .nav-item:nth-child(1) {
    transition-delay: 0.05s;
  }
  
  .navbar-collapse.show .nav-item:nth-child(2) {
    transition-delay: 0.1s;
  }
  
  .navbar-collapse.show .nav-item:nth-child(3) {
    transition-delay: 0.15s;
  }
  
  .navbar-collapse.show .nav-item:nth-child(4) {
    transition-delay: 0.2s;
  }
  
  .navbar-collapse.show .nav-item:nth-child(5) {
    transition-delay: 0.25s;
  }
  
  /* Join Us button animation - properly contained in black background */
  .navbar-collapse form.d-flex {
    opacity: 0;
    transform: translateY(20px);
    transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1), 
                opacity 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-delay: 0.3s;
  }
  
  .navbar-collapse.show form.d-flex {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Ensure button clicks are responsive */
  a.linktree:active {
    transition: none !important; /* Remove transitions on active state */
  }
  
  .joinus-btn:active {
    transform: scale(0.98); /* Give slight feedback when clicked */
    transition: transform 0.05s linear !important; /* Ultra-fast response */
  }
}

/* Fixed navbar for mobile screens */
@media screen and (max-width: 768px) {
  .navbar {
    position: fixed !important;
    top: 0 !important;
    left: 0;
    right: 0;
    width: 100vw;
    z-index: 1000;
    transition: none !important;
    transform: none !important;
    animation: none !important;
  }
  
  /* Add padding to body to prevent content from going under the navbar */
  body {
    padding-top: 56px; /* Adjust based on your navbar height */
  }
  
  /* Ensure the menu button is always visible */
  .navbar-toggler {
    display: block !important;
    opacity: 1 !important;
    position: relative !important;
    z-index: 1001 !important;
    margin-left: auto; /* Push to right */
    min-width: 40px;
    min-height: 40px;
  }
  
  /* Prevent logo from affecting other elements on hover */
  .scope-logo:hover {
    width: 30%; /* Keep same width on hover */
  }
  
  /* Isolate hover effects */
  .navbar-toggler:hover {
    background-color: transparent;
  }
  
  /* Make sure logo and toggler don't interfere with each other */
  .container-fluid.d-flex {
    gap: 10px;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Make sure dropdown menus appear below the navbar */
  .navbar-collapse {
    max-height: calc(100vh - 56px);
    overflow-y: auto;
  }
}
