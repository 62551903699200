* {
  padding: 0px;
  margin: 0px;
}

::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "turnkey-bold";
  src: url("../public/fonts/TurnkeyLite-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "turnkey-medium";
  src: url("../public/fonts/TurnkeyLite-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "turnkey-light";
  src: url("../public/fonts/TurnkeyLite-ExtraLight.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'MADE-OUTER-SANS';
  src: url("../public/fonts/MADEOuterSansBoldPERSONALUSE.otf") format('opentype');
}

.green-container {
  width: 100%;
  height: 112vh;
  display: flex;
  justify-content: center;
}

.inner-container {
  position: relative; /* Needed for absolute positioning */
  width: 98%;
  height: 87%;
  border-radius: 0px 0px 19.548px 19.548px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.33) 0%,
    rgba(9, 16, 0, 0.37) 54.9%,
    rgba(35, 63, 0, 0.5) 76.4%,
    rgba(68, 124, 0, 0.5) 98.4%
  );
}

.circle-light {
  position: relative;
  top: 30%;
  left: 40%;
  width: 20%;
  height: 20%;
  border-radius: 500px;
  background-color: rgba(87, 201, 90, 0.5);
  filter: blur(303.035400390625px);
}

.grid-image-left {
  width: 40%;
  height: 100%;
  position: relative;
  bottom: 0%;
  background-image: url("../public/images/Vector1.png");
  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;
}

.grid-image-right {
  width: 40%;
  height: 100%;
  position: relative;
  bottom: 0%;
  background-image: url("../public/images/Vector2.png");
  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left bottom;
  z-index: 2;
}

.top-layer {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  top: 30%;
}

.head {
  font-family: turnkey-bold;
  color: white;
  font-size: 500%;
  letter-spacing: -2px;
  margin-bottom: 0px;
}

.highlighted {
  font-family: turnkey-bold;
  color: #36be00;
}

.caption {
  margin-top: 1%;
  font-family: turnkey-light;
  font-size: 16px;
  font-weight: 100;
  color: white;
  width: 60%;
  line-height: 154.513%; /* 25.29px */
  letter-spacing: 1px;
  text-align: center;
}

.hw-container {
  margin-bottom: 3%;
  width: 230px;
  height: 8vh;
  border-radius: 27.44px;
  border: 0.61px solid #36be00;
  box-shadow: 0px 0px 4.878px 2.439px rgba(54, 190, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 2%;
  padding-right: 2%;
}

.hw-text {
  font-family: turnkey-light;
  font-size: 1.5rem;
  color: white;
  margin: 0px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hw-arrow {
  width: 20%;
  height: 90%;
  position: relative;
  background-image: url("../public/images/arrow.png");
  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.fontChange {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

@keyframes expandCircle {
  0% {
    width: 0px;
    height: 0px;
    opacity: 0;
  }
  100% {
    width: var(--circle-size);
    height: var(--circle-size);
    opacity: 1;
  }
}

div.circles {
  width: 50%;
  height: 10vh;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 100%;
  left: 25%;
  opacity: 0.3;
}

div.circle1,
div.circle2,
div.circle3 {
  position: absolute;
  border-radius: 50%;
  animation: expandCircle 0.6s cubic-bezier(0.25, 1, 0.5, 1) forwards;
}

/* Circle 1 */
div.circle1 {
  --circle-size: 300px;
  background: rgba(0, 135, 4, 0.5);
  animation-delay: 0s;
}

/* Circle 2 */
div.circle2 {
  --circle-size: 400px;
  background: rgba(43, 145, 46, 0.2);
  animation-delay: 0.15s;
}

/* Circle 3 */
div.circle3 {
  --circle-size: 500px;
  background: rgba(43, 145, 46, 0.2);
  animation-delay: 0.3s;
}

.inside-scope {
  width: 100%;
  height: 100%;
  margin-top: 10%;
  margin-bottom: 10%;
  display: flex;
  align-items: center;
}

@keyframes expandBlurredCircle {
  from {
    width: 0;
    height: 0;
    opacity: 1;
    filter: blur(50px);
  }
  to {
    width: 40%;
    height: 80%;
    opacity: 1;
    filter: blur(200px);
  }
}

.is-circle-light {
  position: absolute;
  align-items: center;
  z-index: 10;
  height: 150%;
  width: 150%;
  border-radius: 500px;
  background: rgba(51, 217, 11, 0.25);
  filter: blur(100px);
  opacity: 0; /* Initially hidden */
  animation: expandBlurredCircle 1s ease-out forwards;
  animation-play-state: paused; /* Pause the animation initially */
}

/* Use a scroll-based approach to trigger the animation */
@media (prefers-reduced-motion: no-preference) {
  .is-circle-light {
    animation-play-state: running; /* Start the animation */
    animation-timeline: view();
  }
}

.is-top-layer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  z-index: 50;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 0%;

}

.is-image {
  width: 130%;
  height: 70vh;
  overflow: hidden;
  display: flex;

  align-items: center;
}

spline-viewer {
  transform: scale(1.3); /* Adjust scale to zoom in */
  width: 120%; /* Make it wider */
  height: 120%; /* Make it taller */
}

.is-contents {
  align-items: start;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.is-head {
  font-family: turnkey-light;
  color: white;
  font-size: 5vw;
  letter-spacing: -2px;
  margin: 0px;
}

.is-caption {
  width: 80%;
  font-size: 18px;
  color: white;
  font-family: turnkey-light;
  line-height: 30px; /* 150% */
  letter-spacing: 0.05rem;
}

body {
  background-color: black;
  margin: 0;
}

.circle {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 99999999; /* Ensures it stays on top */
  opacity: 0.8;
  transition: transform 0.1s ease-out;
}

/*What do we do?*/

.wdwd {
  width: 100%;
  height: 100%;
  padding: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0px;
  padding-bottom: 0px;
}

.wdwd-head {
  font-size: 60px;
  font-family: turnkey-light;
  color: white;
}

.wdwd-main-container {
  margin: 10%;
  margin-top: 60px;
}

.wdwe-01-container,
.wdwe-02-container,
.wdwe-03-container,
.wdwe-04-container {
  display: flex;
  align-items: center;
  padding-top: 50px;
  gap: 10%;
}

.wdwd-01-num,
.wdwd-02-num,
.wdwd-03-num,
.wdwd-04-num {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #36be00;
  color: transparent;
  font-family: turnkey-bold;
  font-size: 300px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  transition: color 0.3s ease-in-out;
}

.wdwd-01,
.wdwd-02,
.wdwd-03,
.wdwd-04 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.wdwd-01-head,
.wdwd-02-head,
.wdwd-03-head,
.wdwd-04-head {
  width: fit-content;
  padding-bottom: 10px;
  color: #fff;
  font-family: "FONTSPRING DEMO - Turnkey Medium";
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-bottom: 4px solid #33d90b;
  margin: 0px;
}

.wdwd-01-content,
.wdwd-02-content,
.wdwd-03-content,
.wdwd-04-content {
  color: #ffffffdb;
  font-family: turnkey-light;
  font-size: 16px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  letter-spacing: 0.05rem;
}

/*brand-events */

.brand-events {
  height: 70vh;
  padding-top: 50px;
  margin-bottom: 200px;
  margin: 140px;
  display: flex;
  flex-direction: column;
  gap: 20%;
  margin-top: 0px;
}

.brand-events-top-container20{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.brand-events-head {
  font-size: 60px;
  font-family: turnkey-bold;
  color: white;
}

.brand-events-caption {
  color: #ffffffdb;
  font-family: turnkey-light;
  font-size: 16px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  letter-spacing: 0.05rem;
  margin-top: 20px;
}

.logo-container {
  width: 100%;
  display: grid;
  gap: 60px;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.logo {
  width: 60%;
  height: auto;
  object-fit: contain;
}

.line{
  color: #808080;
  height: 1px;
  width: 100%;
}

.footer-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10%;
  height: 115%;
  padding: 5%;  
}


.footer-bottom-container{
  width: 90%;
  display: flex;
  align-items: flex-start;
  gap: 15%;
  margin-top: 10%;
}

.footer-top-container{
  width: 100%;
  display: flex;
  padding: 4%;
  align-items: center;
  justify-content: center;
  gap: 70px;
  padding-bottom: 0px;
}

.scope,.mlrit{
  width: 20%;
  height: 7vh;
  position: relative;
  background-image: url("../public/images/scope.png");
  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.mlrit{
  background-image: url("../public/images/mlrit.png");
  height: 5vh;
}

.flwus-text,.writeus-text,.meet-text{
  font-family: turnkey-medium;
  font-size: 2.5rem;
  color: #33d90b;
}

.flwus-content,.writeus-content,.meet-content{
  font-family: turnkey-light;
  font-size: 1rem;
  color: #fff;
  letter-spacing: 0.05rem;
}

.social{
  display: flex;
}

.twitter,.instagram,.linkedIn,.github{
  height: 10vh;
  width: 30%;
  margin: 10px;
  margin-top: 0px;
  fill: #fff;
  cursor: pointer;
}


.explore-resources {
  margin-top: 0%;
  height: 100%;
}

.explore-resources-head {
  font-size: 60px;
  font-family: turnkey-bold;
  color: white;
}

.explore-resources {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding:140px
}

.explore-resources-caption{
  font-family: turnkey-light;
  font-size: 16px;
  color: #ffffffdb;
  letter-spacing: 0.05rem;
  margin-top: 2%;
}

.explore-resources-btn{
  font-family: turnkey-bold;
  font-size: 18px;
  color: #fff;
  background-color: #36be00;
  width: 10%;
  border-radius: 27.44px;
  padding: 10px 20px;
  border: 1px solid white;
}


/* Updated Logo Carousel Styling */
.resorces-logos {
  width: 100%;
  overflow: hidden;
  padding: 40px 0;
  position: relative;
  background: rgba(0, 0, 0, 0.15); /* Lighter, more subtle background */
  border-radius: 12px;
  margin: 20px 0;
}

/* Remove the gradient overlays */
.resorces-logos::before,
.resorces-logos::after {
  display: none; /* Hide the gradient overlays */
}

/* Also remove track gradients */
.logo-track::before,
.logo-track::after {
  display: none; /* Hide track gradients */
}

/* Enhance logo visibility */
.logo-slide img {
  height: 75%;
  width: 75%;
  object-fit: contain;
  opacity: 0.9; /* Slightly more visible */
  transition: all 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
}

/* More pronounced hover effect */
.logo-slide:hover img {
  transform: scale(1.2);
  opacity: 1;
  filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.3));
}

.logo-carousel {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.logo-track {
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
}

.track-left {
  animation: scroll-left 30s linear infinite;
}

.track-right {
  animation: scroll-right 30s linear infinite;
}

.logo-slide {
  height: 70px;
  width: 120px;
  margin: 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease;
}

/* Animation keyframes */
@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes scroll-right {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}

/* Animation pauses on hover */
.logo-track:hover { 
  animation-play-state: paused;
}

.end-footer{
  height: 15px;
  width: 100%;
  background-color: #36be00;
}

/* Increase social media icon size */
svg.twitter, svg.instagram, svg.linkedIn, svg.github {
  height: 36px;  /* Increased from 30px */
  width: 36px;   /* Increased from 30px */
  margin-top: 10px;
}

/* Adjust spacing between icons */
.social-link {
  display: inline-block;
  margin: 0px;  /* Increased from 10px to provide more spacing between larger icons */
  transition: all 0.3s ease;
  position: relative;
  gap: 0px;
}

/* Make the hover effect slightly more pronounced for larger icons */
.social-link:hover svg {
  filter: drop-shadow(0 0 10px rgba(51, 217, 11, 0.8));
  transform: scale(1.15);
}

/* Specific glow colors for each platform */
.social-link:hover .twitter {
  filter: drop-shadow(0 0 8px rgba(29, 161, 242, 0.8));
}

.social-link:hover .instagram {
  filter: drop-shadow(0 0 8px rgba(225, 48, 108, 0.8));
}

.social-link:hover .linkedIn {
  filter: drop-shadow(0 0 8px rgba(0, 119, 181, 0.8));
}

.social-link:hover .github {
  filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.8));
}

/* Size variation for logos entering and exiting */
@keyframes logo-grow-enter {
  0% { transform: scale(0.7); opacity: 0.3; }
  100% { transform: scale(1); opacity: 1; }
}

@keyframes logo-shrink-exit {
  0% { transform: scale(1); opacity: 1; }
  100% { transform: scale(0.7); opacity: 0.3; }
}

/* Apply GSAP animations with JavaScript */

.codesense-section{
  height: 70vh;
  width: 100vw;
  padding:9%;
  display: flex;
  gap: 20px;
  margin-bottom: 10%;
}

.codesense-left{
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
}

.codesense-left-head{
  font-family: turnkey-bold;
  font-size: 60px;
  color: #36be00;
}

.codesense-left-caption{
  font-family: turnkey-light;
  font-size: 16px;
  color: #ffffffdb;
  letter-spacing: 0.05rem;
  margin-top: 2%;
}

.codesense-left-btn{
  font-family: turnkey-bold;
  font-size: 18px;
  color: #fff;
  background-color: #36be00;
  width: fit-content;
  border-radius: 27.44px;
  padding: 10px 30px;
  border: 1px solid white;
  cursor: pointer;
  text-decoration: none;
}

.codesense-left-btn-link{
  text-decoration: none;
  color: #fff;
}




.codesense-right{
  width: 50%;
  height: 100%;
}

.codesense-image{
  width: 100%;
  height: fit-content;
}


/* Desktop: 2x4 grid */
@media (min-width: 1024px) {
  .logo-container {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, auto);
  }
}

/* Tablet: 3x3 grid */
@media (min-width: 768px) and (max-width: 1023px) {
  .logo-container {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, auto);
  }
  .circle {
    visibility: hidden;
  }
}

/* Mobile: 3x3 grid */
@media (max-width: 768px) {
  
  .green-container{
    height: 100vh;
  }

  .hw-container {
    height: 6vh;
    width: 30%;
    padding-right: 5px;
  }

  .top-layer{
    top: 25%;
  }

  .head{
    text-align: center;
    margin-top: 5%;
    font-size: 50px;
    margin-bottom: 5%;
    line-height: 55px;
  }

  .caption{
    width: 80%;
    font-size: 15px;
  }

  .hw-container{
    width: 150px;
    height: 5vh;
  }


  .hw-text {
    font-size: 1.1rem;
  }

  .inside-scope{
    margin-top: 0%;
  }

  .is-head{
    font-size: 13vw;
    
  }

  .is-caption{
    font-size: 13px;
    width: 100%;
    line-height: 20px;
    letter-spacing: 0.01rem;
  }

  .is-top-layer{
    flex-direction: column;
    margin-top: 10%;
  }

  .is-image{
    height: 50vh;
    width: 200%;
  }
  /* Circle 1 */
  div.circle1 {
    --circle-size: 300px;
  }

  /* Circle 2 */
  div.circle2 {
    --circle-size: 400px;
  }

  /* Circle 3 */
  div.circle3 {
    --circle-size: 500px;
  }
  .circle {
    visibility: hidden;
    pointer-events: none;
  }

  .wdwd-head{
    font-size: 45px;
    margin-top: 30%;

  }

  .wdwd-main-container{
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin: 5%;
  }

  .wdwd-01-num,
  .wdwd-02-num,
  .wdwd-03-num,
  .wdwd-04-num {
    font-size: 50px;
    width: fit-content;
    -webkit-text-stroke-width: 0.5px;
  }

  .wdwd-01-head,
  .wdwd-02-head,
  .wdwd-03-head,
  .wdwd-04-head {
    font-family: turnkey-bold;
    font-size: 30px;
    border-bottom: 1px solid #33d90b;
  }

  .wdwd-01-content,
  .wdwd-02-content,
  .wdwd-03-content,
  .wdwd-04-content {
    font-size: 13px;
  }

  .brand-events-head, .explore-resources-head {
    font-size: 37px;
  }

  .brand-events {
    height: 50vh;
    margin: 10px;
    padding: 3%;
    margin-top: 30%;
  }

  .explore-resources{
    padding: 5%;
    margin-top: 35%;
    margin-bottom: 25%;
  }

  .brand-events-caption{
    font-size: 13px;
  }

  .logo-container {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, auto);
    gap: 25px;
  }

  .flwus-text,.writeus-text,.meet-text{
    font-family: turnkey-medium;
    font-size: 20px;
    color: #33d90b;
  }

  .flwus-content,.writeus-content,.meet-content{
    font-size: 10px;
    letter-spacing: 0.2px;
  }


  .footer-top-container{
    gap: 5%;
  }

  .footer-bottom-container{
    flex-direction: column;
    gap: 50px;
  }

  .social{
    gap: 10px;
  }

  .twitter,.instagram,.linkedIn,.github{
    height: fit-content;
    width: 15%;
    margin: 3px;
    margin-top: 10px;
    fill: #fff;
    cursor: pointer;
  }

  .is-image {
    width: 100%;
    height: 70vh;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  .end-footer{
    height: 10px;
  }
  
  spline-viewer {
    transform: scale(1.2); /* Adjust scale to zoom in */
    width: 110%; /* Make it wider */
    height: 110%; /* Make it taller */
  }

  .inner-container {
    height: 97%;
  }

  
  .brand-events {
    gap: 40px;
  }


  .explore-resources-btn{
    width: 30%;
    height: 10%;
  }
}

/* Mobile adjustments for logo carousel */
@media screen and (max-width: 768px) {
  .logo-slide {
    height: 50px;
    width: 80px;
    margin: 0 15px;
  }
  
  .logo-slide img {
    height: 65%;
    width: 65%;
  }
  
  .logo-carousel {
    gap: 20px;
  }
  
  /* Make animation slightly faster on mobile */
  .track-left {
    animation: scroll-left 25s linear infinite;
  }
  
  .track-right {
    animation: scroll-right 25s linear infinite;
  }
}

/* Extra small screens */
@media screen and (max-width: 480px) {
  .logo-slide {
    height: 40px;
    width: 60px;
    margin: 0 10px;
  }
  
  .logo-carousel {
    gap: 15px;
    padding: 0 5px;
  }
  
  .resorces-logos {
    padding: 25px 0;
  }
}

/* Ensure mobile responsiveness with larger icons */
@media screen and (max-width: 768px) {
  svg.twitter, svg.instagram, svg.linkedIn, svg.github {
    height: 30px;
    width: 30px;
  }
  
  .social-link {
    margin: 0px;
  }
}

/* Fixed navbar for mobile screens */
@media screen and (max-width: 768px) {
  .navbar {
    position: fixed !important;
    top: 0 !important;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
    transition: none !important;
    transform: none !important;
    animation: none !important;
  }
  
  /* Add padding to body to prevent content from going under the navbar */
  body {
    padding-top: 56px; /* Adjust based on your navbar height */
  }
  
  /* Ensure the menu button is always visible */
  .navbar-toggler {
    display: block;
    opacity: 1 !important;
  }
  
  /* Make sure dropdown menus appear below the navbar */
  .navbar-collapse {
    max-height: calc(100vh - 56px);
    overflow-y: auto;
  }

  
.codesense-section{
  display: flex;
  flex-direction: column;
  padding: 5%;
  gap: 100px;
  height: 90vh;
}

.codesense-left{
  width: 100%;
  gap: 5px;
}

.codesense-right{
  width: 100%;
}

.codesense-left-head{
  font-size: 37px;
}
}

a#logo {
  visibility: hidden;
}
