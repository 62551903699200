.events-h >h3 {
  margin-top: 30px;
  font-weight: 500;
  font-size: 3.5rem;
  line-height: 1;
  text-align: center;
}

.but {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 50px 0;
  perspective: 800px;
}

.btn-41 {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 180px;
  padding: 14px 24px;
  border-radius: 8px;
  font-family: 'turnkey-medium', sans-serif;
  font-size: 16px;
  letter-spacing: 0.5px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
  outline: none;
  border: none;
  box-shadow: 
    0 4px 15px rgba(0, 0, 0, 0.3),
    inset 0 1px 1px rgba(255, 255, 255, 0.1);
  transform-style: preserve-3d;
  user-select: none;
  white-space: nowrap;
  text-align: center;
}

/* Past Events Button */
.btn41-44 {
  background: rgba(15, 15, 15, 0.95);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}

.btn41-44:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  transition: all 0.6s ease;
}

.btn41-44:hover {
  background: rgba(25, 25, 25, 0.95);
  border-color: rgba(255, 255, 255, 0.3);
  transform: translateY(-3px);
  box-shadow: 
    0 8px 25px rgba(0, 0, 0, 0.4),
    inset 0 1px 1px rgba(255, 255, 255, 0.15);
}

.btn41-44:hover:before {
  left: 100%;
}

.btn41-44.active {
  background: rgba(40, 40, 40, 0.95);
  border-color: rgba(255, 255, 255, 0.4);
  box-shadow: 
    0 6px 20px rgba(0, 0, 0, 0.5),
    0 0 15px rgba(255, 255, 255, 0.1),
    inset 0 1px 1px rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

.btn41-44.active:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: white;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

/* Upcoming Events Button */
.btn41-45 {
  background: rgba(54, 190, 0, 0.15);
  color: #36be00;
  border: 1px solid rgba(54, 190, 0, 0.3);
  text-shadow: 0 0 5px rgba(54, 190, 0, 0.2);
}

.btn41-45:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(54, 190, 0, 0.15),
    transparent
  );
  transition: all 0.6s ease;
}

.btn41-45:hover {
  background: rgba(54, 190, 0, 0.2);
  border-color: rgba(54, 190, 0, 0.5);
  color: white;
  transform: translateY(-3px);
  box-shadow: 
    0 8px 25px rgba(0, 0, 0, 0.4),
    0 0 20px rgba(54, 190, 0, 0.3),
    inset 0 1px 1px rgba(54, 190, 0, 0.2);
  text-shadow: 0 0 8px rgba(54, 190, 0, 0.5);
}

.btn41-45:hover:before {
  left: 100%;
}

.btn41-45.active {
  background: rgba(54, 190, 0, 0.3);
  border-color: rgba(54, 190, 0, 0.6);
  color: white;
  box-shadow: 
    0 6px 20px rgba(0, 0, 0, 0.4),
    0 0 20px rgba(54, 190, 0, 0.4),
    inset 0 1px 1px rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

.btn41-45.active:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: #36be00;
  box-shadow: 0 0 10px rgba(54, 190, 0, 0.8);
}

/* Pulsing effect for active upcoming button */
@keyframes pulse-glow {
  0% {
    box-shadow: 
      0 6px 20px rgba(0, 0, 0, 0.4),
      0 0 5px rgba(54, 190, 0, 0.4);
  }
  50% {
    box-shadow: 
      0 6px 20px rgba(0, 0, 0, 0.4),
      0 0 20px rgba(54, 190, 0, 0.6);
  }
  100% {
    box-shadow: 
      0 6px 20px rgba(0, 0, 0, 0.4),
      0 0 5px rgba(54, 190, 0, 0.4);
  }
}

.btn41-45.active {
  animation: pulse-glow 2s infinite;
}

/* Improved mobile styling for event filter buttons */
@media screen and (max-width: 600px) {
  .but {
    gap: 15px;
    margin: 30px 0;
    width: 100%;
    padding: 0 20px;
  }
  
  .btn-41 {
    min-width: 145px;
    height: 45px;
    padding: 0 15px;
    font-size: 12px;
    letter-spacing: 0.2px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Ensure text is always white and centered */
  .btn41-44, .btn41-45 {
    color: white !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    white-space: nowrap;
  }
  
  /* For extra small screens */
  @media screen and (max-width: 400px) {
    .but {
      flex-direction: row;
      justify-content: center;
      gap: 10px;
      padding: 0 5px;
    }
    
    .btn-41 {
      min-width: 140px;
      width: 140px;
      font-size: 11px;
      padding: 0 10px;
      letter-spacing: 0px;
    }
  }
}

/* Ensure consistent text color & position across all states */
.btn41-44, 
.btn41-45, 
.btn41-44:hover, 
.btn41-45:hover, 
.btn41-44.active, 
.btn41-45.active {
  color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
}

.events1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin: 0 auto;
  padding: 2rem 1rem 4rem;
  max-width: 1400px;
}

/* Filter button styling */
.but {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem 0;
  width: 100%;
}

.btn41-44, .btn41-45 {
  padding: 10px 24px;
  font-weight: 500;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  text-decoration: none;
  border: 2px solid rgb(255, 255, 255);
  z-index: 1;
  color: white;
  margin: 0 10px;
  border-radius: 4px;
  font-family: 'turnkey-light';
}

.btn41-44:after, .btn41-45:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  direction: rtl;
  z-index: -1;
  background: #36be00;
  transition: all 0.3s ease;
}

.btn41-44:hover, .btn41-45:hover {
  color: rgb(0, 0, 0);
  border-color: #36be00;
}

.btn41-44:hover:after, .btn41-45:hover:after {
  left: auto;
  right: 0;
  width: 100%;
}

/* Staggered animation for cards */
.events1 > div {
  opacity: 0;
  animation: staggerFadeIn 0.5s ease forwards;
}

@keyframes staggerFadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply staggered delay to each card */
.events1 > div:nth-child(1) { animation-delay: 0.1s; }
.events1 > div:nth-child(2) { animation-delay: 0.2s; }
.events1 > div:nth-child(3) { animation-delay: 0.3s; }
.events1 > div:nth-child(4) { animation-delay: 0.4s; }
.events1 > div:nth-child(5) { animation-delay: 0.5s; }
.events1 > div:nth-child(6) { animation-delay: 0.6s; }